import React, { useCallback, useState, FC, useEffect } from 'react'
import { getParentDepartment } from '@moonpig/web-core-utils'
import { Box, Container, Flex } from '@moonpig/launchpad-components'
import { styled, breakpointUp, breakpointDown } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { Breadcrumbs } from '@moonpig/web-core-nav'
import {
  useHandleAddToBasket,
  AddedToBasketEvent,
  DispatchBanner,
  useProductModalEvents,
  useBasket,
  DispatchCutOffQuery,
  PageTypeEnum,
} from '@moonpig/web-shared-products'
import { useCustomerId } from '@moonpig/web-shared-utils'
import { trackGAEvent } from '@moonpig/web-core-analytics'
import { DepartmentsEnum } from '@moonpig/web-explore-types-graphql'
import { useFlag } from '@moonpig/web-explore-flags'
import { useExperimentString } from '@moonpig/web-core-eppo/browser'
import { CmsContent, SearchFiltersFacet } from '../../pages/GalleryPage/types'
import { generateBreadcrumbsClickGAEvent } from '../../analytics/GAEvents'
import { GalleryProduct } from '../ProductGridWithPaging'
import { InternalUserGalleryInfo } from '../InternalUserGalleryInfo'
import { FixedBottomBar } from '../FixedBottomBar'
import { SoldOutGalleryMessage } from './SoldOutGalleryMessage'
import { Header } from './Header'
import { ProductGridWithTracking } from '../ProductGridWithTracking'
import { useProductListingPageContext } from '../../utils/productListingPageContext'
import { OfferBanner } from '../OfferBanner'
import {
  getSortByOptions,
  useResetProductListing,
  useSetUIHCookie,
} from '../../utils'
import { LoadMoreType } from '../types'
import { useFindLocaleText } from '../../text-localisation'
import { usePromoTile } from './usePromoTile'
import { QuickFilters } from '../QuickFilters/QuickFilters'
import { FiltersMenu } from '../FiltersMenu'
import { FiltersCarousel } from '../FiltersCarousel'
import { useSearchStore } from '../../store/SearchStore'
import { InternalUserGalleryInfoVariant } from '../InternalUserGalleryInfoVariant'

const StyledBox = styled(Box)`
  ${s({
    bgcolor: 'colorBackground01',
  })}
`

const StyledHeader = styled(Box)`
  ${() =>
    s({
      paddingTop: 4,
      paddingLeft: { xs: 6, lg: 10, xxl: 12 },
      paddingRight: 6,
      margin: 0,
    })}
`

const StyledFlex = styled(Flex)`
  width: 100%;
  flex-direction: row;

  ${breakpointDown('md')} {
    flex-direction: column;
  }
`

const StyledGridContainer = styled(Container)`
  position: relative;
  width: 100%;
`

const TrackableDiv = styled.div`
  flex: 0 0 auto;
  ${s({
    mb: 4,
  })}
`

const StyledTextContainer = styled(Box)`
  ${breakpointUp('md')} {
    ${s({
      p: 4,
      m: 6,
      minHeight: '66px',
    })}
  }

  a {
    ${s({
      typography: 'typeBodyLabel',
    })}
  }
`

type GalleryProps = {
  content: CmsContent
  metaTitle: string
  products: GalleryProduct[]
  loadMoreProducts: (loadMoreType: LoadMoreType) => Promise<boolean>
  presetFacets: SearchFiltersFacet[]
  experiments?: { [key: string]: string } | undefined
  dispatchCutOffData?: DispatchCutOffQuery
  shouldDisplaySoldOutMessage?: boolean
  groupCardProject?: string
  totalClickRankDocumentCount: number
  isInternalUser: boolean
  totalCount: number
}

export const Gallery: FC<GalleryProps> = ({
  content,
  products,
  loadMoreProducts,
  presetFacets,
  experiments,
  dispatchCutOffData,
  shouldDisplaySoldOutMessage,
  metaTitle,
  groupCardProject,
  totalClickRankDocumentCount,
  isInternalUser,
  totalCount,
}) => {
  useResetProductListing(0)
  const [_, { setLoading, setSuccess, setError }] = useBasket()
  const customerId = useCustomerId()
  const localiseText = useFindLocaleText()
  const shouldUseSearchStore = useFlag('search-state-management-refactor')
  const [state] = useProductListingPageContext()
  const { setUIHCookie } = useSetUIHCookie()
  const [isRecentlyViewedOpen, setRecentlyViewedOpen] = useState(false)
  const selectedFilters = useSearchStore(store => store.selectedFilters)
  const preAppliedFilters = useSearchStore(store => store.preAppliedFilters)
  const searchContext = useSearchStore(store => store.searchContext)
  const storeLoadingFlag = useSearchStore(store => store.loading)

  const loadingProducts = shouldUseSearchStore
    ? storeLoadingFlag
    : state.loading

  const useDropdownFilters =
    useExperimentString('search-use-dropdown-filters-v2', {
      fallback: 'disabled',
    })?.toLowerCase() === 'enabled'

  const useNewGalleryUX =
    useExperimentString('search-product-tile-ux', {
      fallback: 'disabled',
    })?.toLowerCase() === 'enabled'

  useProductModalEvents(event => {
    if (event.type === 'PRODUCT_MODAL_CLOSED') {
      setRecentlyViewedOpen(true)
    }
  })

  const { title, description, breadcrumbsItems, offer } = content
  const { promotionId, searchTerm, departments } = searchContext

  const offerBanner = OfferBanner({ offerText: offer })

  const soldOutMessage = localiseText('find.sold_out')
  const itsNotYouMessage = localiseText('find.its_not_you')

  const { handleAddToBasket, addingToBasket } = useHandleAddToBasket({
    pageLocation: 'gallery',
    soldOutMessage,
    itsNotYouMessage,
    groupCardProject,
  })

  useEffect(() => {
    setLoading(addingToBasket)
  }, [addingToBasket, setLoading])

  const onAddedToBasket = useCallback(
    async (e: AddedToBasketEvent) => {
      const {
        product,
        variant,
        quantity,
        selectedAddon,
        buttonActionName,
        requiresEditingValue,
        componentName,
        redirectUser,
        giftXSellTrackingData,
      } = e
      setUIHCookie({ product })
      try {
        await handleAddToBasket({
          quantity,
          product,
          variant,
          filters: [
            ...selectedFilters.map(f => ({ facetKey: f.id, group: f.parent })),
            ...preAppliedFilters.map(f => ({
              facetKey: f.key,
              group: f.group,
            })),
          ],
          experiments: { ...experiments },
          searchTerm,
          selectedAddon,
          itemIndex: product?.productIndex || 0,
          productsLength: totalCount,
          buttonActionName,
          requiresEditingValue,
          componentName,
          redirectUser,
          giftXSellTrackingData,
        })
        setSuccess(true)
      } catch (error) {
        setError(true)
      }
    },
    [
      setUIHCookie,
      handleAddToBasket,
      selectedFilters,
      preAppliedFilters,
      experiments,
      searchTerm,
      totalCount,
      setSuccess,
      setError,
    ],
  )

  const promoTile = usePromoTile(content.promoTile)

  const dispatchCutOff =
    dispatchCutOffData?.contentDepartments[0]?.dispatchCutOff

  const sortByOptions = getSortByOptions({
    isCards: getParentDepartment(departments[0]) === DepartmentsEnum.ALL_CARDS,
    localiseText,
  })

  return (
    <StyledBox>
      {((products && products.length > 0) || loadingProducts) && (
        <>
          {offerBanner ||
            (dispatchCutOff && (
              <DispatchBanner dispatchCutOff={dispatchCutOff} />
            ))}
          <StyledHeader>
            <Box>
              <TrackableDiv
                onClick={() => {
                  trackGAEvent(
                    generateBreadcrumbsClickGAEvent(breadcrumbsItems),
                  )
                }}
                data-testid="breadcrumb-header"
              >
                <Breadcrumbs items={breadcrumbsItems} />
              </TrackableDiv>
              <Header totalCount={totalCount} title={title} />
              {!loadingProducts &&
                description &&
                departments[0] === DepartmentsEnum.DIGITAL_GIFTS && (
                  <Box
                    mb={6}
                    /* eslint-disable-next-line react/no-danger */
                    dangerouslySetInnerHTML={{ __html: description }}
                    data-testid="gallery-description"
                  />
                )}
            </Box>
            {
              /* istanbul ignore next */ useNewGalleryUX && isInternalUser && (
                <InternalUserGalleryInfoVariant
                  productCount={totalCount}
                  searchTerm={searchTerm}
                  filters={presetFacets}
                  promotionId={promotionId}
                  totalClickRankDocumentCount={totalClickRankDocumentCount}
                />
              )
            }
          </StyledHeader>
          <StyledFlex>
            {!useNewGalleryUX && isInternalUser && (
              <InternalUserGalleryInfo
                productCount={totalCount}
                searchTerm={searchTerm}
                filters={presetFacets}
                promotionId={promotionId}
                totalClickRankDocumentCount={totalClickRankDocumentCount}
              />
            )}
            <FiltersMenu sortOptions={sortByOptions} />
            <StyledGridContainer padding={0}>
              {useDropdownFilters && <QuickFilters departments={departments} />}
              <FiltersCarousel />
              <ProductGridWithTracking
                totalCount={totalCount}
                searchContext={searchContext}
                pageTitle={title}
                metaTitle={metaTitle}
                products={products}
                loadMoreProducts={loadMoreProducts}
                onAddedToBasket={onAddedToBasket}
                isInternalUser={isInternalUser}
                presetFacets={presetFacets}
                experiments={experiments}
                pageType={PageTypeEnum.GALLERY}
                customerId={customerId}
                groupCardProject={groupCardProject}
                promoTile={promoTile}
              />
            </StyledGridContainer>
          </StyledFlex>
          {!loadingProducts && description && (
            <StyledTextContainer>
              <div
                /* eslint-disable-next-line react/no-danger */
                dangerouslySetInnerHTML={{ __html: description }}
                data-testid="gallery-description"
              />
            </StyledTextContainer>
          )}
        </>
      )}
      {shouldDisplaySoldOutMessage && (
        <SoldOutGalleryMessage department={departments} />
      )}
      <FixedBottomBar
        isOpen={isRecentlyViewedOpen}
        onClose={() => setRecentlyViewedOpen(false)}
        onOpen={() => setRecentlyViewedOpen(true)}
        pageTitle={title}
      />
    </StyledBox>
  )
}
